import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import AddBots from "../pages/bots/AddBots";
import Bots from "../pages/bots/Bots";
import IndividualBots from "../pages/bots/IndividualBots";
import AddCoin from "../pages/coins/AddCoin";
import Coins from "../pages/coins/Coins";
import IndividualCoins from "../pages/coins/IndividualCoins";
import Homepage from "../pages/homepage/Homepage";
import IndividualStreamer from "../pages/homepage/IndividualStreamer";
import LoginPage from "../pages/login/LoginPage";
import AddMarket from "../pages/markets/AddMarket";
import IndividualMarket from "../pages/markets/IndividualMarket";
import Markets from "../pages/markets/Markets";
import Page2 from "../pages/page2/Page2";
import Streamstreamer from "../pages/homepage/Streamstreamer";
import Viewers from "../pages/viewers/Viewers";
import Views from "../pages/views/Views";
import Newcalender from "../pages/homepage/Newcalender";

function AppRouting() {
  return (
    <Router>
      <AppLayout>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/streamers" element={<Homepage />} />
          <Route path="/streamers/:id" element={<IndividualStreamer />} />
          <Route path="/streams" element={<Page2 />} />
          <Route path="/viewers" element={<Viewers />} />
          <Route path="/views" element={<Views />} />
          <Route path="/coins" element={<Coins />} />
          <Route path="/coins/:id" element={<IndividualCoins />} />
          <Route path="/coins/add" element={<AddCoin />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/markets/:id" element={<IndividualMarket />} />
          <Route path="/markets/add" element={<AddMarket />} />
          <Route path="/bots/add" element={<AddBots />} />
          <Route path="/bots/:id" element={<IndividualBots />} />
          <Route path="/bots" element={<Bots />} />
          <Route path="/streamerstream" element={<Streamstreamer />} />
          <Route path="/Newcalender" element={<Newcalender />} />
        </Routes>
      </AppLayout>
    </Router>
  );
}

export default AppRouting;
