import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../common/constants/APIEndpoints";
// /verifyAdminToken
function AuthGuard({ children }) {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");

  const verifyUser = () => {
    if (!authToken) {
      navigate("/");
    } else {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      axios
        .get(`${BASE_URL}/api/user/verifyAdminToken`, config)
        .then((res) => res.data.error && navigate("/"))
        .catch((err) => navigate("/"));
    }
  };

  useEffect(() => {
    verifyUser();
  }, []);

  return <div>{children}</div>;
}

export default AuthGuard;
