import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function AddBots() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    token: "",
    totalAmount: 0,
    buyStop: 0,
    sellStop: 0,
    status: "",
    buyAmount: 0,
    sellAmount: 0,
  });

  const auth_token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${auth_token}` },
  };

  const addNewBot = () => {
    axios
      .post(`${BASE_URL}/api/admin/addOrderBot`, data, config)
      .then((res) => navigate(`/bots/${res.data._id}`));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
      }}
    >
      <p>
        <span style={{ fontWeight: "bold" }}>Token:</span>{" "}
        <>
          <input
            value={data.token}
            onChange={(e) => setData({ ...data, token: e.target.value })}
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Total Amount:</span>{" "}
        <>
          <input
            value={data.totalAmount}
            onChange={(e) => setData({ ...data, totalAmount: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Buy Stop:</span>{" "}
        <>
          <input
            value={data.buyStop}
            onChange={(e) => setData({ ...data, buyStop: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Sell Stop:</span>{" "}
        <>
          <input
            value={data.sellStop}
            onChange={(e) => setData({ ...data, sellStop: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Buy Amount:</span>{" "}
        <>
          <input
            value={data.buyAmount}
            onChange={(e) => setData({ ...data, buyAmount: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Sell Amount:</span>{" "}
        <>
          <input
            value={data.sellAmount}
            onChange={(e) => setData({ ...data, sellAmount: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Status:</span>{" "}
        <>
          <select
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value="live">Live</option>
            <option value="stop">Stop</option>
          </select>
        </>
      </p>
      <br />
      <button className="normal-button" onClick={() => addNewBot()}>
        Add
      </button>
    </div>
  );
}

export default AddBots;
