import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { subtractDate } from "../../common/functions/subtractDate";
import UITable from "../../common/table/UITable";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../common/functions/getAuthToken";
import { getFormattedDate } from "../../common/functions/date.functions";

const token = getAuthToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Markets() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: moment().format("YYYY-MM-DDTHH:MM"),
  });

  const getMarketAdmin = (from, to) => {
    axios
      .get(`${BASE_URL}/api/admin/getMarketAdmin?from=${from}&to=${to}`, config)
      .then((res) => setData(res.data));
  };

  useEffect(() => {
    const urlSplit = window.location.href.split("?");
    if (!urlSplit || urlSplit.length <= 1) {
      getMarketAdmin(filterData.from, filterData.to);
      return;
    }

    const params = urlSplit[1].split("&");

    if (!params || params.length <= 1) return;

    const from = params[0].split("from=")[1];
    const to = params[1].split("to=")[1];

    setFilterData({
      from,
      to,
    });
    getMarketAdmin(from, to);
  }, [window.location.href]);

  let columns = [
    {
      field: "_id",
      hide: false,
      headerName: "ID",
      width: 200,
      // valueFormatter: (params) => params?.value.str,
    },
    {
      field: "base_coin_symbol",
      hide: false,
      headerName: "Base Coin",
      width: 100,
    },
    {
      field: "quote_coin_symbol",
      hide: false,
      headerName: "Quote Coin",
      width: 100,
    },
    {
      field: "base_pool",
      hide: false,
      headerName: "Base Pool",
      width: 200,
    },
    {
      field: "quote_pool",
      hide: false,
      headerName: "Quote Pool",
      width: 200,
    },
    {
      field: "last_price",
      hide: false,
      headerName: "Last Price",
      width: 200,
    },
    {
      field: "createdAt",
      hide: false,
      headerName: "Created At",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "updatedAt",
      hide: false,
      headerName: "Last Updated",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];
  let rows = data;

  const applyFilter = () => {
    const fromDate = new Date(filterData.from);
    const toDate = new Date(filterData.to);

    const fromFormattedDate = getFormattedDate(fromDate);
    const toFormattedDate = getFormattedDate(toDate);

    navigate(`/markets?from=${fromFormattedDate}&to=${toFormattedDate}`);
  };

  return (
    <div>
      <div className="tabs-page-container">
        <div>
          <p>From</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.from}
            onChange={(e) =>
              setFilterData({ ...filterData, from: e.target.value })
            }
          />
        </div>
        <div style={{ padding: "0 20px" }}>
          <p>To</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.to}
            onChange={(e) =>
              setFilterData({ ...filterData, to: e.target.value })
            }
          />
        </div>
        <p>or</p>
        <div style={{ padding: "0 20px" }}>
          <p>Select timeline:</p>
          <select
            name="timeline"
            id="timeline"
            defaultValue="month"
            onChange={(e) => {
              setFilterData({
                ...filterData,
                from: subtractDate(e.target.value),
                to: moment().format("YYYY-MM-DDTHH:mm:ss"),
              });
            }}
          >
            <option value="day">Last 24hrs</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <button
          onClick={applyFilter}
          style={{
            padding: "5px 10px",
            borderRadius: "5px",
            border: "unset",
          }}
        >
          Apply Filter
        </button>
        <button
          style={{
            padding: "5px 10px",
            borderRadius: "5px",
            border: "unset",
            marginLeft: "10px",
          }}
          onClick={() => navigate("/markets/add")}
        >
          Add Market
        </button>
      </div>
      <UITable
        columns={columns}
        rows={rows}
        onRowClick={(params) => navigate(`/markets/${params.row._id}`)}
      />
    </div>
  );
}

export default Markets;
