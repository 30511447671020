import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";

function LoginPage() {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    username: "",
    password: "",
  });

  const onLogin = (e) => {
    setShowError(false);
    setLoading(true);

    axios
      .post(`${BASE_URL}/api/user/login-admin`, data)
      .then((res) => {
        if (res.data?.error) {
          setShowError(true);
        } else {
          localStorage.setItem("token", res.data.token);
          navigate("/streamerstream");
        }
      })
      .catch((err) => {
        setShowError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" textAlign="center">
          <Image src="https://i.ibb.co/ryMvZDv/logo.png" /> Log-in to your
          account
        </Header>
        <Form size="large">
          <Segment stacked>
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Username"
              onChange={(e) =>
                setData((prev) => ({ ...prev, username: e.target.value }))
              }
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e) =>
                setData((prev) => ({ ...prev, password: e.target.value }))
              }
            />

            <Button
              fluid
              size="large"
              onClick={onLogin}
              loading={loading}
              disabled={loading}
            >
              Login
            </Button>
          </Segment>
        </Form>
        {showError ? (
          <Message negative>
            <Message.Header>Login Failed</Message.Header>
            <p>Incorrect credentials</p>
          </Message>
        ) : null}
      </Grid.Column>
    </Grid>
  );
}

export default LoginPage;
