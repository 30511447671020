import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function IndividualBots() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState({
    _id: "",
    status: "live",
    token: "",
    totalAmount: 0,
    buyStop: 0,
    sellStop: 0,
    updatedAt: "",
    createdAt: "",
  });

  const token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    getOrderBot();
  }, []);
  const getOrderBot = () => {
    axios.get(`${BASE_URL}/api/admin/getOrderBot/${id}`, config).then((res) => setData(res.data));
  };
  const deleteBot = () => {
    axios.delete(`${BASE_URL}/api/admin/deleteBot/${id}`, config).then((res) => setData(res.data));
  };

  const saveEditedBot = () => {
    axios
      .patch(
        `${BASE_URL}/api/admin/saveEditedBot/${data._id}`,
        {
          status: data.status,
          token: data.token,
          totalAmount: data.totalAmount,
          buyStop: data.buyStop,
          sellStop: data.sellStop,
          updatedAt: data.updatedAt,
          createdAt: data.createdAt,
        },
        config
      )
      .then((res) => console.log(res));
  };

  const [isEdit, setIsEdit] = useState(false);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "20px" }}>
      <p>
        <span style={{ fontWeight: "bold" }}>Object ID:</span> <>{data._id}</>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Token:</span>{" "}
        {!isEdit ? (
          <>{data.token}</>
        ) : (
          <>
            <input value={data.token} onChange={(e) => setData({ ...data, token: e.target.value })} />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Total Amount:</span>{" "}
        {!isEdit ? (
          <>{data.totalAmount}</>
        ) : (
          <>
            <input value={data.totalAmount} onChange={(e) => setData({ ...data, totalAmount: e.target.value })} type="number" />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Status</span>{" "}
        {!isEdit ? (
          <>{data.status}</>
        ) : (
          <select defaultValue={data.status} disabled={!isEdit} onChange={(e) => setData({ ...data, status: e.target.value })}>
            <option value="live">Live</option>
            <option value="stop">Stop</option>
          </select>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Buy Stop:</span>{" "}
        {!isEdit ? (
          <>{data.buyStop}</>
        ) : (
          <>
            <input value={data.buyStop} onChange={(e) => setData({ ...data, buyStop: e.target.value })} type="number" />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Sell stop</span>{" "}
        {!isEdit ? (
          <>{data.sellStop}</>
        ) : (
          <>
            <input value={data.sellStop} onChange={(e) => setData({ ...data, sellStop: e.target.value })} type="number" />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Created at:</span> <>{moment(data.createdAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Updated at:</span> <>{moment(data.updatedAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <br />
      <div>
        {!isEdit ? (
          <button onClick={() => setIsEdit(true)}>Edit</button>
        ) : (
          <button
            onClick={() => {
              saveEditedBot();
              setIsEdit(false);
            }}
          >
            Save
          </button>
        )}
        <button style={{ marginLeft: "10px" }} onClick={() => navigate("/bots/add")}>
          Add Bot
        </button>
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            deleteBot();
            navigate("/bots");
          }}
        >
          Delete Bot
        </button>
      </div>
    </div>
  );
}

export default IndividualBots;
