/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";
import { subtractDate } from "../../common/functions/subtractDate";
import {
  getFormattedDate,
  getSimpleDate,
} from "../../common/functions/date.functions";
import UITable from "../../common/table/UITable";

const token = getAuthToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Streamstreamer() {
  const [data, setData] = useState([
    {
      email: "",
      username: "",
      name: "",
      totaldaysstreamed: "",
      dateStreamedCount: "",
      totalDuration: "",
      uniqueWatcher: "",
      _id: 1,
    },
  ]);
  const [totalNumberOfWallets, setTotalNumberOfWallets] = useState(0);
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: moment().format("YYYY-MM-DDTHH:MM"),
  });
  function navigateToUser(params) {
    let streamDays = [];
    data.forEach((streamer) => {
      if (streamer._id === params.row._id) {
        streamDays = streamer.datesStreamed;
      }
    });
    navigate(
      `/streamers/${params.row._id}?from=${moment(filterData.from).format(
        "YYYY-MM-DD"
      )}&to=${moment(filterData.to).format("YYYY-MM-DD")}`,
      {
        state: [filterData, { streamDays: streamDays }],
      }
    );
  }

  const getData = (from, to) => {
    axios
      .get(
        `${BASE_URL}/api/admin/teststreamer2?from=${getSimpleDate(
          new Date(from)
        )}&to=${getSimpleDate(new Date(to))}`,
        config
      )
      .then((res) => {
        setData(
          res.data.streames || {
            email: "",
            username: "",
            name: "",
            totaldaysstreamed: "",
            dateStreamedCount: "",
            totalDuration: "",
            uniqueWatcher: "",
            _id: 1,
          }
        );
      });
  };

  const getTotalNumberOfWallets = async () => {
    axios
      .get(`${BASE_URL}/api/admin/get-total-number-of-wallets`, config)
      .then((res) => {
        if (typeof res.data !== "object") return;
        setTotalNumberOfWallets(res.data.totalNumberOfWallets ?? 0);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getTotalNumberOfWallets();
  }, []);

  useEffect(() => {
    const urlSplit = window.location.href.split("?");
    if (!urlSplit || urlSplit.length <= 1) {
      applyFilter();
      return;
    }

    const params = urlSplit[1].split("&");

    if (!params || params.length <= 1) return;

    const from = params[0].split("from=")[1];
    const to = params[1].split("to=")[1];

    setFilterData({
      from,
      to,
    });
    getData(from, to);
  }, [window.location.href]);

  const applyFilter = () => {
    const fromDate = new Date(filterData.from);
    const toDate = new Date(filterData.to);

    const fromFormattedDate = getFormattedDate(fromDate);
    const toFormattedDate = getFormattedDate(toDate);

    navigate(`/streamerstream?from=${fromFormattedDate}&to=${toFormattedDate}`);
  };

  const getHoursMinutesString = (duration) => {
    const hours = Math.floor(duration / 60);
    const minutes = Math.round(duration % 60);
    return hours + "h " + minutes + "m";
  };

  let columns = [
    {
      field: "username",
      hide: false,
      headerName: "Username",
      width: 200,
    },
    {
      field: "email",
      hide: false,
      headerName: "Email",
      width: 200,
    },
    {
      field: "totaldaysstreamed",
      hide: false,
      headerName: "Total days",
      width: 200,
    },
    {
      field: "countValidDays",
      hide: false,
      headerName: "Valid days",
      width: 200,
    },
    {
      field: "eventCount",
      hide: false,
      headerName: "Total Streams/Events",
      width: 200,
    },
    {
      field: "totalDuration",
      hide: false,
      headerName: "Total Min streamed",
      width: 200,
      valueGetter: (params) => `${Math.round(params.row.totalDuration)}`,
    },
    {
      field: "",
      hide: false,
      headerName: "Total Hour streamed",
      width: 200,
      valueGetter: (params) => {
        return getHoursMinutesString(params.row.totalDuration);
      },
    },
    {
      field: "timeband",
      hide: false,
      headerName: "Timeband",
      width: 450,
      valueGetter: (params) => {
        const timeband = params.row.timeband;
        if (!timeband) return "";

        return (
          "Morning: " +
          getHoursMinutesString(timeband.morning) +
          " | Afternoon: " +
          getHoursMinutesString(timeband.afternoon) +
          " | Evening: " +
          getHoursMinutesString(timeband.evening)
        );
      },
    },

    // {
    //   field: "viewers",
    //   hide: false,
    //   headerName: "unique Viewer",
    //   width: 200,
    // },
  ];
  let visibleFields = ["name", "id", "updatedDate"];

  let rows = data;

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <p>Total number of wallets: {totalNumberOfWallets}</p>
        <div className="tabs-page-container">
          <div style={{ paddingRight: "20px" }}>
            <p>Start Date</p>
            <input
              type="datetime-local"
              id="birthdaytime"
              name="birthdaytime"
              value={filterData.from}
              onChange={(e) =>
                setFilterData({ ...filterData, from: e.target.value })
              }
            />
          </div>
          <div style={{ padding: "0 20px" }}>
            <p>End Date</p>
            <input
              type="datetime-local"
              id="birthdaytime"
              name="birthdaytime"
              value={filterData.to}
              onChange={(e) =>
                setFilterData({ ...filterData, to: e.target.value })
              }
            />
          </div>
          <p>or</p>
          <div style={{ padding: "0 20px" }}>
            <p>Select timeline:</p>
            <select
              name="timeline"
              id="timeline"
              defaultValue="month"
              onChange={(e) => {
                setFilterData({
                  ...filterData,
                  from: subtractDate(e.target.value),
                  to: moment().format("YYYY-MM-DDTHH:mm:ss"),
                });
              }}
            >
              <option value="day">Last 24hrs</option>
              <option value="week">Last week</option>
              <option value="month">Last month</option>
              <option value="year">Last year</option>
            </select>
          </div>
          <button
            onClick={applyFilter}
            style={{ marginLeft: "10px" }}
            className="normal-button"
          >
            Apply Filter
          </button>
        </div>
        <UITable
          columns={columns}
          rows={rows}
          visibleFields={visibleFields}
          onRowClick={(params) => {
            navigateToUser(params);
          }}
        />
      </div>
    </div>
  );
}

export default Streamstreamer;
