import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";
import { subtractDate } from "../../common/functions/subtractDate";
import UITable from "../../common/table/UITable";

const token = getAuthToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Viewers() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: "",
  });

  useEffect(() => {
    getStreamData();
  }, []);

  const getStreamData = () => {
    axios
      .get(
        `${BASE_URL}/api/admin/getViewersDataFiltered?from=${filterData.from}&to=${filterData.to}`,
        config
      )
      .then((res) => setData(res.data));
  };

  let columns = [
    {
      field: "_id",
      hide: false,
      headerName: "ID",
      width: 200,
      // valueFormatter: (params) => params?.value.str,
    },
    {
      field: "name",
      hide: false,
      headerName: "Name",
      width: 200,
    },
    {
      field: "email",
      hide: false,
      headerName: "Email",
      width: 200,
    },
    {
      field: "phoneNumber",
      hide: false,
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "followers",
      hide: false,
      headerName: "Followers",
      width: 100,
    },
    {
      field: "following",
      hide: false,
      headerName: "Following",
      width: 100,
    },
    {
      field: "total_watchtime",
      hide: false,
      headerName: "Total Watchtime",
      width: 100,
    },
    {
      field: "avg_watchtime",
      hide: false,
      headerName: "Average Watchtime",
      width: 100,
    },
    {
      field: "createdAt",
      hide: false,
      headerName: "Created At",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "updatedAt",
      hide: false,
      headerName: "Last Updated",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];
  let visibleFields = ["name", "id", "updatedDate"];
  let rows = data;

  return (
    <div>
      <div className="tabs-page-container">
        <div style={{ paddingRight: "20px" }}>
          <p>From</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.from}
            onChange={(e) =>
              setFilterData({ ...filterData, from: e.target.value })
            }
          />
        </div>
        <div style={{ padding: "0 20px" }}>
          <p>To</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.to}
            onChange={(e) =>
              setFilterData({ ...filterData, to: e.target.value })
            }
          />
        </div>
        <p>or</p>
        <div style={{ padding: "0 20px" }}>
          <p>Select timeline:</p>
          <select
            name="timeline"
            id="timeline"
            defaultValue="month"
            onChange={(e) => {
              setFilterData({
                ...filterData,
                from: subtractDate(e.target.value),
                to: moment().format("YYYY-MM-DDTHH:mm:ss"),
              });
            }}
          >
            <option value="day">Last 24hrs</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <button
          onClick={getStreamData}
          style={{ marginLeft: "10px" }}
          className="normal-button"
        >
          Apply Filter
        </button>
      </div>
      <UITable columns={columns} rows={rows} visibleFields={visibleFields} />
    </div>
  );
}

export default Viewers;
