import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function AddCoin() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    contract_address: "",
    decimal: 0,
    name: "",
    price: 0,
    streamer_id: "",
    symbol: "",
  });
  const [streamerList, setStreamerList] = useState([]);

  const token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getStreamerNameList = () => {
    axios
      .get(`${BASE_URL}/api/admin/getStreamerNameList`, config)
      .then((res) => setStreamerList(res.data));
  };

  useEffect(() => {
    getStreamerNameList();
  }, []);

  const addNewCoin = () => {
    axios
      .post(`${BASE_URL}/api/admin/addCoin`, data, config)
      .then((res) => navigate(`/coins/${res.data._id}`));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
      }}
    >
      <p>
        <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
        <>
          <input
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Symbol:</span>{" "}
        <>
          <input
            value={data.symbol}
            onChange={(e) => setData({ ...data, symbol: e.target.value })}
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Streamer Username:</span>{" "}
        <>
          <select
            onChange={(e) => setData({ ...data, streamer_id: e.target.value })}
          >
            <option value={null}> </option>
            {streamerList.map((streamer, i) => (
              <option value={streamer?._id}>{streamer?.username}</option>
            ))}
          </select>
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Contract Address:</span>{" "}
        <>
          <input
            value={data.contract_address}
            onChange={(e) =>
              setData({ ...data, contract_address: e.target.value })
            }
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Price:</span>{" "}
        <>
          <input
            value={data.price}
            onChange={(e) => setData({ ...data, price: e.target.value })}
            type="number"
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Decimal:</span>{" "}
        <>
          <input
            value={data.decimal}
            onChange={(e) => setData({ ...data, decimal: e.target.value })}
            type="number"
          />
        </>
      </p>
      <br />
      <button className="normal-button" onClick={() => addNewCoin()}>
        Add
      </button>
    </div>
  );
}

export default AddCoin;
