import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import LogoutConfirmModal from "../components/modals/logoutConfirmModal";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const tab_values = [
    {
      name: "Streamers",
      link: "/streamerstream",
    },
    {
      name: "Streams",
      link: "/streams",
    },
    {
      name: "Viewers",
      link: "/viewers",
    },
    {
      name: "Views",
      link: "/views",
    },
    {
      name: "Coins",
      link: "/coins",
    },
    {
      name: "Markets",
      link: "/markets",
    },
    {
      name: "Bots",
      link: "/bots",
    },
  ];
  return (
    <>
      <LogoutConfirmModal open={openLogoutModal} setOpen={setOpenLogoutModal} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 0px 10px 0px",
          borderBottom: "2px solid #aaa",
        }}
      >
        <div style={{ display: "flex" }}>
          {tab_values.map((tab, i) => (
            <div
              key={i}
              style={{
                marginRight: "30px",
                fontWeight: "700",
                cursor: "pointer",
                padding: "5px 0px",
              }}
              className={`${
                location.pathname == tab.link ? "active-page-tab" : ""
              }`}
              onClick={() => navigate(tab.link)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <div
          className="logout-container"
          onClick={() => setOpenLogoutModal(true)}
        >
          Logout
          <Icon name="sign-out" size="large" />
        </div>
      </div>
    </>
  );
}

export default Header;
