export const getAuthToken = () => {
  const authToken = localStorage.getItem("token");
  return authToken;
};

export const getAuthConfig = () => {
  const config = {
    headers: { Authorization: `Bearer ${getAuthToken()}` },
  };

  return config;
};

export const logout = (navigate = null) => {
  localStorage.removeItem("token");
  const route = "/";
  navigate ? navigate(route) : (window.location.href = route);
};
