import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import { logout } from "../../functions/getAuthToken";
import "./logoutConfirmModal.css";

function LogoutConfirmModal({ open, setOpen }) {
  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header icon="sign-out" content="Are you sure you want to logout?" />
      <Modal.Actions>
        <Button
          color="red"
          className="logout-modal-button-green"
          onClick={() => setOpen(false)}
        >
          <Icon name="remove" /> No
        </Button>
        <Button
          color="green"
          className="logout-modal-button-red"
          onClick={() => logout()}
        >
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default LogoutConfirmModal;
