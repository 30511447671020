import "./App.css";
import AppRouting from "./routing/AppRouting";
import "semantic-ui-css/semantic.min.css";

function App() {
  return (
    <div className="App">
      <AppRouting />
    </div>
  );
}

export default App;
