import axios from "axios";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";
import { subtractDate } from "../../common/functions/subtractDate";
import UITable from "../../common/table/UITable";

const token = getAuthToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Homepage() {
  const [data, setData] = useState([]);
  console.log(data);
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: "",
  });

  useEffect(() => {
    getStreamersData();
  }, []);

  const getStreamersData = () => {
    axios.get(`${BASE_URL}/api/admin/getStreamersDataFiltered?from=${filterData.from}&to=${filterData.to}`, config).then((res) => setData(res.data))
   
  };
  console.log(data);

  let columns = [
    {
      field: "_id",
      hide: false,
      headerName: "ID",
      width: 200,
      // valueFormatter: (params) => params?.value.str,
    },
    {
      field: "name",
      hide: false,
      headerName: "Name",
      width: 200,
    },
    {
      field: "username",
      hide: false,
      headerName: "Username",
      width: 200,
    },
    {
      field: "email",
      hide: false,
      headerName: "Email",
      width: 200,
    },
    {
      field: "phoneNumber",
      hide: false,
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "totalDuration",
      hide: false,
      headerName: "Total Duration",
      width: 200,
    },
    {
      field: "durationPerViewer",
      hide: false,
      headerName: "Duration Per Viewer",
      width: 200,
    },
    {
      field: "hourPerViewer",
      hide: false,
      headerName: "Hour Per Viewer",
      width: 200,
    },
    {
      field: "totalViewers",
      hide: false,
      headerName: "Total Viewers",
      width: 200,
    },
    {
      field: "viewersPerStream",
      hide: false,
      headerName: "Viewers Per Stream",
      width: 200,
    },
    {
      field: "createdAt",
      hide: false,
      headerName: "Created At",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "updatedAt",
      hide: false,
      headerName: "Last Updated",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  let visibleFields = ["name", "id", "updatedDate"];

  let rows = data;
  return (
    <div>
      <p style={{ textAlign: "start", marginBottom: "20px" }}>Filters:</p>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <div style={{ padding: "0 20px" }}>
          <p>Start Date</p>
          <input type="datetime-local" id="birthdaytime" name="birthdaytime" value={filterData.from} onChange={(e) => setFilterData({ ...filterData, from: e.target.value })} />
        </div>
        <div style={{ padding: "0 20px" }}>
          <p>End Date</p>
          <input type="datetime-local" id="birthdaytime" name="birthdaytime" value={filterData.to} onChange={(e) => setFilterData({ ...filterData, to: e.target.value })} />
        </div>
        <p>or</p>
        <div style={{ padding: "0 20px" }}>
          <p>Select timeline:</p>
          <select
            name="timeline"
            id="timeline"
            defaultValue="month"
            onChange={(e) => {
              setFilterData({ ...filterData, from: subtractDate(e.target.value), to: moment().format("YYYY-MM-DDTHH:mm:ss") });
            }}
          >
            <option value="day">Last 24hrs</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <button onClick={getStreamersData}>Apply Filter</button>
      </div>
      <UITable columns={columns} rows={rows} visibleFields={visibleFields} onRowClick={(params) => navigate(`/streamers/${params.row._id}`)} />
    </div>
  );
}

export default Homepage;
