import React, { useState } from 'react'

function Newcalender() {
    const [date, setDate] = useState(new Date());

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const numDays = lastDay.getDate();
  
    const calendar = [];
    let week = [];
  
    for (let i = 0; i < firstDay.getDay(); i++) {
      week.push('');
    }
  
    for (let i = 1; i <= numDays; i++) {
      week.push(i);
      if (week.length === 7) {
        calendar.push(week);
        week = [];
      }
    }
  
    if (week.length > 0) {
      calendar.push(week);
    }
  
    return (
      <div className="calendar">
        <div className="calendar-header">
          <button onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() - 1))}>&lt;</button>
          <div>{months[date.getMonth()]} {date.getFullYear()}</div>
          <button onClick={() => setDate(new Date(date.getFullYear(), date.getMonth() + 1))}>&gt;</button>
        </div>
        <table>
          <thead>
            <tr>
              {days.map(day => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {calendar.map((week, index) => (
              <tr key={index}>
                {week.map((day, index) => (
                  <td key={index}>{day}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

export default Newcalender
