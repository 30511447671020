function getCorrectDateString(number) {
  let result = "";
  if (number < 10) {
    result += "0" + number;
  } else {
    result += number;
  }
  return result;
}

export function getFormattedDate(date) {
  return (
    date.getFullYear() +
    "-" +
    getCorrectDateString(date.getMonth() + 1) +
    "-" +
    getCorrectDateString(date.getDate()) +
    "T" +
    getCorrectDateString(date.getHours()) +
    ":" +
    getCorrectDateString(date.getMinutes())
  );
}

export function getSimpleDate(date) {
  return (
    date.getFullYear() +
    "-" +
    getCorrectDateString(date.getMonth() + 1) +
    "-" +
    getCorrectDateString(date.getDate())
  );
}
