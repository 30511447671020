import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/header/Header";
import AuthGuard from "./AuthGuard";

function AppLayout({ children }) {
  const location = useLocation();
  const defaultLayoutRoutes = ["/"];

  if (defaultLayoutRoutes.includes(location.pathname)) {
    return <div>{children}</div>;
  } else {
    return (
      <AuthGuard>
        <div className="page-content-container">
          <Header />
          {children}
        </div>
      </AuthGuard>
    );
  }
}

export default AppLayout;
