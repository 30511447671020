import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function AddMarket() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [coinsList, setCoinsList] = useState([]);

  const [data, setData] = useState({
    base_id: "",
    quote_id: "",
    base_pool: 0,
    quote_pool: 0,
    last_price: 0,
  });

  const token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    getCoinsList();
  }, []);
  const getCoinsList = () => {
    axios
      .get(`${BASE_URL}/api/admin/getCoinsList`, config)
      .then((res) => setCoinsList(res.data));
  };
  const addNewMarket = () => {
    axios
      .post(
        `${BASE_URL}/api/admin/addMarket`,
        {
          base_id: data.base_id ? data.base_id : coinsList[0]?._id,
          quote_id: data.quote_id ? data.quote_id : coinsList[0]?._id,
          base_pool: data.base_pool,
          quote_pool: data.quote_pool,
          last_price: data.last_price,
        },
        config
      )
      .then((res) => navigate(`/markets/${res.data._id}`));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
      }}
    >
      <p>
        <span style={{ fontWeight: "bold" }}>Base Coin:</span>{" "}
        <>
          <select
            defaultValue={data.base_id}
            onChange={(e) => setData({ ...data, base_id: e.target.value })}
          >
            {coinsList.map((market, i) => (
              <option value={market?._id}>{market?.symbol}</option>
            ))}
          </select>
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Quote Coin:</span>{" "}
        <>
          <select
            defaultValue={data.quote_id}
            onChange={(e) => setData({ ...data, quote_id: e.target.value })}
          >
            {coinsList.map((market, i) => (
              <option value={market?._id}>{market?.symbol}</option>
            ))}
          </select>
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Base Pool:</span>{" "}
        <>
          <input
            value={data.base_pool}
            type="number"
            onChange={(e) => setData({ ...data, base_pool: e.target.value })}
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Quote Pool:</span>{" "}
        <>
          <input
            value={data.quote_pool}
            type="number"
            onChange={(e) => setData({ ...data, quote_pool: e.target.value })}
          />
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Last Price:</span>{" "}
        <>
          <input
            value={data.last_price}
            type="number"
            onChange={(e) => setData({ ...data, last_price: e.target.value })}
          />
        </>
      </p>
      <br />
      <div>
        <button className="normal-button" onClick={() => addNewMarket()}>
          Add market
        </button>
      </div>
    </div>
  );
}

export default AddMarket;
