import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { subtractDate } from "../../common/functions/subtractDate";
import UITable from "../../common/table/UITable";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../common/functions/getAuthToken";

const token = getAuthToken();
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Bots() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    from: subtractDate("month"),
    to: "",
  });

  useEffect(() => {
    getBotsData();
  }, []);

  const getBotsData = () => {
    axios
      .get(
        `${BASE_URL}/api/admin/getBotsDataFiltered?from=${filterData.from}&to=${filterData.to}`,
        config
      )
      .then((res) => setData(res.data));
  };

  let columns = [
    {
      field: "_id",
      hide: false,
      headerName: "ID",
      width: 200,
      // valueFormatter: (params) => params?.value.str,
    },
    {
      field: "status",
      hide: false,
      headerName: "Status",
      width: 200,
    },
    {
      field: "token",
      hide: false,
      headerName: "Token",
      width: 100,
    },
    {
      field: "totalAmount",
      hide: false,
      headerName: "Total Amount",
      width: 200,
    },
    {
      field: "buyStop",
      hide: false,
      headerName: "Buy Stop",
      width: 200,
    },
    {
      field: "sellStop",
      hide: false,
      headerName: "Sell Stop",
      width: 200,
    },
    {
      field: "createdAt",
      hide: false,
      headerName: "Created At",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
    {
      field: "updatedAt",
      hide: false,
      headerName: "Last Updated",
      type: "dateTime",
      width: 200,
      valueFormatter: (params) =>
        moment(params?.value).format("DD/MM/YYYY hh:mm A"),
    },
  ];
  let visibleFields = ["name", "id", "updatedDate"];
  let rows = data;

  return (
    <div>
      <div className="tabs-page-container">
        <div>
          <p>From</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.from}
            onChange={(e) =>
              setFilterData({ ...filterData, from: e.target.value })
            }
          />
        </div>
        <div style={{ padding: "0 20px" }}>
          <p>To</p>
          <input
            type="datetime-local"
            id="birthdaytime"
            name="birthdaytime"
            value={filterData.to}
            onChange={(e) =>
              setFilterData({ ...filterData, to: e.target.value })
            }
          />
        </div>
        <p>or</p>
        <div style={{ padding: "0 20px" }}>
          <p>Select timeline:</p>
          <select
            name="timeline"
            id="timeline"
            defaultValue="month"
            onChange={(e) => {
              setFilterData({
                ...filterData,
                from: subtractDate(e.target.value),
                to: moment().format("YYYY-MM-DDTHH:mm:ss"),
              });
            }}
          >
            <option value="day">Last 24hrs</option>
            <option value="week">Last week</option>
            <option value="month">Last month</option>
            <option value="year">Last year</option>
          </select>
        </div>
        <button
          onClick={getBotsData}
          style={{
            marginLeft: "10px",
          }}
          className="normal-button"
        >
          Apply Filter
        </button>
        <button
          style={{
            marginLeft: "10px",
          }}
          className="normal-button"
          onClick={() => navigate("/bots/add")}
        >
          Add Coin
        </button>
      </div>
      <UITable
        columns={columns}
        rows={rows}
        visibleFields={visibleFields}
        onRowClick={(params) => navigate(`/bots/${params.row._id}`)}
      />
    </div>
  );
}

export default Bots;
