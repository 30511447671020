import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function IndividualCoins() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState({
    contract_address: "",
    createdAt: "",
    decimal: 0,
    name: "",
    price: 0,
    streamer_id: "",
    symbol: "",
    updatedAt: "",
    _id: "",
    streamer_username: "",
  });

  const [streamerList, setStreamerList] = useState([]);

  const token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    getCoin();
    getStreamerNameList();
  }, []);
  const getCoin = () => {
    axios
      .get(`${BASE_URL}/api/admin/getCoin/${id}`, config)
      .then((res) => setData(res.data));
  };
  const getStreamerNameList = () => {
    axios
      .get(`${BASE_URL}/api/admin/getStreamerNameList`, config)
      .then((res) => setStreamerList(res.data));
  };
  const deleteCoin = () => {
    axios
      .delete(`${BASE_URL}/api/admin/deleteCoin/${id}`, config)
      .then((res) => setData(res.data));
  };

  const saveEditedCoin = () => {
    axios
      .patch(
        `${BASE_URL}/api/admin/saveEditedCoin/${data._id}`,
        {
          contract_address: data.contract_address,
          decimal: data.decimal,
          name: data.name,
          price: data.price,
          streamer_id: data.streamer_id ? data.streamer_id : null,
          symbol: data.symbol,
        },
        config
      )
      .then((res) => console.log(res));
  };

  const [isEdit, setIsEdit] = useState(false);

  console.log(data, "DATA");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
      }}
    >
      <p>
        <span style={{ fontWeight: "bold" }}>Object ID:</span> <>{data._id}</>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
        {!isEdit ? (
          <>{data.name}</>
        ) : (
          <>
            <input
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Symbol:</span>{" "}
        {!isEdit ? (
          <>{data.symbol}</>
        ) : (
          <>
            <input
              value={data.symbol}
              onChange={(e) => setData({ ...data, symbol: e.target.value })}
            />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Streamer Username:</span>{" "}
        {/* {!isEdit ? (
          <>{data.streamer_username}</>
        ) : ( */}
        <>
          <select
            defaultValue={data.streamer_id}
            disabled={!isEdit}
            onChange={(e) => setData({ ...data, streamer_id: e.target.value })}
          >
            <option value={data.streamer_id}>{data.streamer_username}</option>
            <option value={null}> </option>
            {streamerList.map((streamer, i) => (
              <option value={streamer?._id}>{streamer?.username}</option>
            ))}
          </select>
        </>
        {/* )} */}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Contract Address:</span>{" "}
        {!isEdit ? (
          <>{data.contract_address}</>
        ) : (
          <>
            <input
              value={data.contract_address}
              onChange={(e) =>
                setData({ ...data, contract_address: e.target.value })
              }
            />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Price:</span>{" "}
        {!isEdit ? (
          <>{data.price}</>
        ) : (
          <>
            <input
              value={data.price}
              onChange={(e) => setData({ ...data, price: e.target.value })}
              type="number"
            />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Decimal:</span>{" "}
        {!isEdit ? (
          <>{data.decimal}</>
        ) : (
          <>
            <input
              value={data.decimal}
              onChange={(e) => setData({ ...data, decimal: e.target.value })}
              type="number"
            />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Created at:</span>{" "}
        <>{moment(data.createdAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Updated at:</span>{" "}
        <>{moment(data.updatedAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <br />
      <div>
        {!isEdit ? (
          <button onClick={() => setIsEdit(true)}>Edit</button>
        ) : (
          <button
            onClick={() => {
              saveEditedCoin();
              setIsEdit(false);
            }}
          >
            Save
          </button>
        )}
        <button
          className="normal-button"
          style={{ marginLeft: "10px" }}
          onClick={() => navigate("/coins/add")}
        >
          Add coin
        </button>
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            deleteCoin();
            navigate("/coins");
          }}
        >
          Delete coin
        </button>
      </div>
    </div>
  );
}

export default IndividualCoins;
