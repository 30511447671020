import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../common/constants/APIEndpoints";
import { getAuthToken } from "../../common/functions/getAuthToken";

function IndividualMarket() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState({
    _id: "",
    base_id: "",
    quote_id: "",
    base_name: "",
    quote_name: "",
    base_pool: 0,
    quote_pool: 0,
    last_price: 0,
    createdAt: "",
    updatedAt: "",
  });

  const [coinsList, setCoinsList] = useState([]);

  const token = getAuthToken();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    getMarket();
    getCoinsList();
  }, []);
  const getMarket = () => {
    axios.get(`${BASE_URL}/api/admin/getMarketIndividualAdmin/${id}`, config).then((res) => setData(res.data));
  };
  const getCoinsList = () => {
    axios.get(`${BASE_URL}/api/admin/getCoinsList`, config).then((res) => setCoinsList(res.data));
  };
  const deleteMarket = () => {
    axios.delete(`${BASE_URL}/api/admin/deleteMarket/${id}`, config).then((res) => setData(res.data));
  };

  const saveEditedMarket = () => {
    axios
      .patch(
        `${BASE_URL}/api/admin/saveEditedMarket/${data._id}`,
        {
          base_id: data.base_id,
          quote_id: data.quote_id,
          base_pool: data.base_pool,
          quote_pool: data.quote_pool,
          last_price: data.last_price,
        },
        config
      )
      .then((res) => console.log(res));
  };

  const [isEdit, setIsEdit] = useState(false);

  console.log(data, "DATA");

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", padding: "20px" }}>
      <p>
        <span style={{ fontWeight: "bold" }}>Object ID:</span> <>{data._id}</>
      </p>

      <p>
        <span style={{ fontWeight: "bold" }}>Base Coin:</span>{" "}
        <>
          <select defaultValue={data.base_id} disabled={!isEdit} onChange={(e) => setData({ ...data, base_id: e.target.value })}>
            <option value={data.base_id}>{data.base_name}</option>
            <option value={null}> </option>
            {coinsList.map((market, i) => (
              <option value={market?._id}>{market?.symbol}</option>
            ))}
          </select>
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Quote Coin:</span>{" "}
        <>
          <select defaultValue={data.quote_id} disabled={!isEdit} onChange={(e) => setData({ ...data, quote_id: e.target.value })}>
            <option value={data.quote_id}>{data.quote_name}</option>
            <option value={null}> </option>
            {coinsList.map((market, i) => (
              <option value={market?._id}>{market?.symbol}</option>
            ))}
          </select>
        </>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Base Pool:</span>{" "}
        {!isEdit ? (
          <>{data.base_pool}</>
        ) : (
          <>
            <input value={data.base_pool} type="number" onChange={(e) => setData({ ...data, base_pool: e.target.value })} />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Quote Pool:</span>{" "}
        {!isEdit ? (
          <>{data.quote_pool}</>
        ) : (
          <>
            <input value={data.quote_pool} type="number" onChange={(e) => setData({ ...data, quote_pool: e.target.value })} />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Last Price:</span>{" "}
        {!isEdit ? (
          <>{data.last_price}</>
        ) : (
          <>
            <input value={data.last_price} type="number" onChange={(e) => setData({ ...data, last_price: e.target.value })} />
          </>
        )}
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Created at:</span> <>{moment(data.createdAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <p>
        <span style={{ fontWeight: "bold" }}>Updated at:</span> <>{moment(data.updatedAt).format("DD/MM/YYYY hh:mm A")}</>
      </p>
      <br />
      <div>
        {!isEdit ? (
          <button onClick={() => setIsEdit(true)}>Edit</button>
        ) : (
          <button
            onClick={() => {
              saveEditedMarket();
              setIsEdit(false);
            }}
          >
            Save
          </button>
        )}
        <button style={{ marginLeft: "10px" }} onClick={() => navigate("/markets/add")}>
          Add market
        </button>
        <button
          style={{ marginLeft: "10px" }}
          onClick={() => {
            deleteMarket();
            navigate("/markets");
          }}
        >
          Delete market
        </button>
      </div>
    </div>
  );
}

export default IndividualMarket;
